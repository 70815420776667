:root {
    --bs-blue: #038edc;
    --bs-indigo: #564ab1;
    --bs-purple: #564ab1;
    --bs-pink: #e83e8c;
    --bs-red: #f34e4e;
    --bs-orange: #f1734f;
    --bs-yellow: #f7cc53;
    --bs-green: #51d28c;
    --bs-teal: #050505;
    --bs-cyan: #5fd0f3;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #74788d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #f5f6f8;
    --bs-gray-300: #eff0f2;
    --bs-gray-400: #e2e5e8;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #74788d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #038edc;
    --bs-secondary: #74788d;
    --bs-success: #51d28c;
    --bs-info: #5fd0f3;
    --bs-warning: #f7cc53;
    --bs-danger: #f34e4e;
    --bs-pink: #e83e8c;
    --bs-light: #f5f6f8;
    --bs-dark: #343a40;
    --bs-purple: #564ab1;
    --bs-primary-rgb: 3, 142, 220;
    --bs-secondary-rgb: 116, 120, 141;
    --bs-success-rgb: 81, 210, 140;
    --bs-info-rgb: 95, 208, 243;
    --bs-warning-rgb: 247, 204, 83;
    --bs-danger-rgb: 243, 78, 78;
    --bs-pink-rgb: 232, 62, 140;
    --bs-light-rgb: 245, 246, 248;
    --bs-dark-rgb: 52, 58, 64;
    --bs-purple-rgb: 86, 74, 177;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 73, 80, 87;
    --bs-body-bg-rgb: 247, 248, 250;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    --bs-body-font-size: 0.9rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #495057;
    --bs-body-bg: #f7f8fa;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #eff0f2;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.25rem;
    --bs-border-radius-sm: 0.2rem;
    --bs-border-radius-lg: 0.4rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-heading-color: #495057;
    --bs-link-color: #038edc;
    --bs-link-hover-color: #025d91;
    --bs-code-color: #f34e4e;
    --bs-highlight-bg: #fcf8e3;
}

.ant-menu-inline-collapsed {
    width: auto !important;
}

.ant-form-item {
    margin-bottom: 0.5rem !important;
}

.ant-input {
    border-radius: 3px !important;
}

.ant-menu-title-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #121926;
    font-weight: 400;
    -webkit-transition: all .4s;
    transition: all .4s;
    font-size: 15px;
    font-family: Roboto, sans-serif;


}

.btn:focus {
    border: none !important;
}

.btn:hover {
    border: none !important;
}

.btn:active {
    border: none !important;
}

.a-table {
    color: blue;
    text-decoration: underline !important;
    cursor: pointer;
}

.icon-table {
    font-size: 20px;
    cursor: pointer;
}

.ant-btn-primary {
    background-color: #009FDA;
}

.ant-btn-primary:not(:disabled):hover {
    color: #fff;
    background-color: #018ec2;
}

.page-title-right {
    margin-right: 10px;
    margin-top: 12px;
    color: rgb(18, 25, 38);
}

.breadcrumb-item>a {
    color: rgb(18, 25, 38);
}

.btn-green {
    background-color: #7AB800;
    color: white;
}

.btn-green:hover {
    color: #fff;
    background-color: #70a801 !important;
    border-color: #70a801 !important;
}

.btn-red {
    background-color: #D52B1E;
    color: white;
}

.btn-red:hover {
    color: #fff;
    background-color: #c2261b !important;
    border-color: #c2261b !important;
}

.btn-blue {
    background-color: #00338D;
    color: white;
}

.btn-blue:hover {
    color: #fff;
    background-color: #002e7e !important;
    border-color: #002e7e !important;
}

.icon-red {
    color: #D52B1E;
}

.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "";
}

.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}

.ant-input-disabled,
.ant-input[disabled] {
    color: #696969
}

.icn-btn {
    margin-right: 3px;
    margin-bottom: 2px;
}

.icn-btn-right {
    margin-left: 3px;
    margin-bottom: 2px;
}

.vertical-menu {
    width: 290px;
    z-index: 999;
}

.mr-btn {
    margin-right: 5px;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error {
    color: black !important;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-list-item-name,
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-icon .anticon {
    color: black !important;
}

.ant-collapse {
    background-color: white;
}

.label-ex {
    color: rgba(126, 147, 160, 0.6) !important;
    font-size: 12px !important;
    font-weight: 100;
}

.label-det {
    padding-top: 5px;
    color: #8d8d8d !important;

}

.label-det-warp {
    inline-size: 100%;
}

.query-btn{
    width: 125px !important;
}

.mr-label {
    margin-left: 45px;
    margin-right: 45px;
}

.f-right {
    float: right;
}

.t-number {
    width: 120px;
}

.ml-checkbox {
    margin-left: 15px;
}


.d-table-desc {
    width: 150px !important;
}

.ant-picker-header-view {
    text-align: left !important;
    margin-left: 10px;
}

.ant-picker-header-prev-btn {
    float: right !important;
    position: absolute !important;
    left: 230px !important;
}

.ant-picker-header-super-prev-btn {
    display: none !important;
}

.ant-picker-header-super-next-btn {
    display: none !important;
}

.ant-picker-body table thead tr>th:nth-child(1) {
    color: white;
}

.ant-picker-body table thead tr>th:nth-child(2) {
    color: white;
}

.ant-picker-body table thead tr>th:nth-child(3) {
    color: white;
}

.ant-picker-body table thead tr>th:nth-child(4) {
    color: white;
}

.ant-picker-body table thead tr>th:nth-child(5) {
    color: white;
}

.ant-picker-body table thead tr>th:nth-child(6) {
    color: white;
}

.ant-picker-body table thead tr>th:nth-child(7) {
    color: white;
}

.ant-picker-body table thead tr>th:nth-child(1)::before {
    color: black;
    content: 'S';
    position: absolute;
    visibility: visible;
    left: 13px;
}

.ant-picker-body table thead tr>th:nth-child(2)::before {
    color: black;
    content: 'M';
    position: absolute;
    visibility: visible;
    left: 13px;
}

.ant-picker-body table thead tr>th:nth-child(3)::before {
    color: black;
    content: 'T';
    position: absolute;
    visibility: visible;
    left: 13px;
}

.ant-picker-body table thead tr>th:nth-child(4)::before {
    color: black;
    content: 'W';
    position: absolute;
    visibility: visible;
    left: 13px;
}

.ant-picker-body table thead tr>th:nth-child(5)::before {
    color: black;
    content: 'T';
    position: absolute;
    visibility: visible;
    left: 13px;
}

.ant-picker-body table thead tr>th:nth-child(6)::before {
    color: black;
    content: 'F';
    position: absolute;
    visibility: visible;
    left: 13px;
}

.ant-picker-body table thead tr>th:nth-child(7)::before {
    color: black;
    content: 'S';
    position: absolute;
    visibility: visible;
    left: 13px;
}

.label-note {
    color: red !important;
}
.ml-label {
    margin-left: 5px;
}
.div-select{
    font-size: 18px;
    color: #5f6368;
    background-color: rgba(241,243,244,0.871);
    justify-content: center;
    margin: 4px 0;
    min-height: 48px;
    padding: 6px 16px;
    flex-wrap: wrap;
    margin-top: 15px;
    text-align: center;
}
.mlt-label {
    margin-left: 15px;
}

.search-result{
    margin: 15px 10px;
    font-weight: 500;
}
.date-picker-full{
    width: 100%;
}
.panel-action{
    position: relative;
}
.panel-action-fixed{
    position: fixed;
    top: 52px;
    z-index: 3;
    left: 310px;
    background: white;
}
.panel-main{
    top: 70px;
}
.table-thead-fixed{
    position: fixed !important;
    z-index: 3 !important;
}

.idoc-table{
    width: 100%;
    text-align: start;
    border-radius: 8px 8px 0 0;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: auto;
    margin-bottom: 25px;
}
.idoc-table>thead>tr:first-child >*:first-child{
    border-start-start-radius: 8px;
}
.idoc-table>thead>tr:last-child >*:last-child{
    border-start-start-radius: 8px;
}
.idoc-table>thead>tr>th{
    position: relative;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    text-align: center;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.2s ease;
    padding: 16px 16px;
    overflow-wrap: break-word;
}
.idoc-table>tbody>tr:hover{
    background: #fafafa;
}
.idoc-table>tbody>tr>td{
    transition: background 0.2s,border-color 0.2s;
    border-bottom: 1px solid #f0f0f0;
    position: relative;
    padding: 16px 16px;
    overflow-wrap: break-word;
    box-sizing: border-box;
}

.ql-editor{
    min-height: 125px;
}

.ql-picker.ql-version {
    width: 160px;
  }
  
  .ql-picker.ql-version .ql-picker-item::before, 
  .ql-picker.ql-version .ql-picker-label::before {
    content: 'Version'
  }
  
  .ql-picker.ql-version [data-value="0"]::before {
    content: 'Current Version'
  }

  .ql-picker.ql-version [data-value="1"]::before {
    content: 'Latest Version'
  }
  
  .ql-picker.ql-version [data-value="2"]::before {
    content: 'Before Latest Version'
  }
  
  .disable .ant-checkbox-wrapper,.disable .icon-table{
    display: none;
  }
.sticky-container {
    width: 350px;
    position: absolute;
    z-index: 9;
    top: 137px;
    right: 10px;
    cursor: move;
    height: auto;
  }
  
  .sticky-outer {
    display: flex;
    padding-top: 92.5925926%;
    position: relative;
  
    width: 100%;
  }
  .sticky-content div {
    padding: 10px;
    padding-bottom: 20px;
}
.sticky {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 200px;
}
  .sticky:before {
    box-shadow: -2px 2px 15px 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.25);
    content: '';
    width: 90%;
    left: 5px;
    height: 83%;
    position: absolute;
    top: 30%;
  }
.sticky-content {
    background: linear-gradient( 180deg, rgba(187, 235, 255, 1) 0%, rgba(187, 235, 255, 1) 12%, rgba(170, 220, 241, 1) 75%, rgba(195, 229, 244, 1) 100% );
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: start;
    padding: 10px;
    font-family: 'Kalam', cursive;
    font-size: 0.8rem;
    clip-path: url(#stickyClip);
    min-height: 195px;
    max-height: 500px;
    white-space: pre-line;
    overflow-y: auto;
    padding-bottom: 30px;
}

  @media screen and (min-width: 640px) {
    .sticky:before {
      height: 79%;
      width: 90%;
    }
    .sticky-content {
        font-size: 1.25rem;
    }
  }
  
  @media screen and (min-width: 768px) {
    .sticky:before {
      height: 75%;
      width: 90%;
    }
    .sticky-content {
        font-size: 1.5rem;
    }
  }
  
  @media screen and (min-width: 1024px) {
    .sticky:before {
      height: 73%;
      width: 90%;
    }
    .sticky-content {
      font-size: 1rem;
    }
  }
.idoc-tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  .idoc-tooltip .tooltiptext {
    visibility: hidden;
    min-width: 400px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    top:-185px;
    position: absolute;
    z-index: 9;
  }
  .idoc-tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .error{
    color: red
  }

  .form-check-input{
    width: 1.1em;
    height: 1.1em;
  }

  label.label input[type="file"] {
    position: absolute;
    top: -1000px;
  }
  .label {
    cursor: pointer;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 5px 15px;
    margin: 5px;
    background: #dddddd;
    display: inline-block;
  }
  .label:hover {
    color: white;
    background: #009FDA;
  }
  .label:active {
    background: #9fa1a0;
  }
  .label:invalid + span {
    color: #000000;
  }
  .label:valid + span {
    color: #ffffff;
  }
  .loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; 
  }
  
  .loading-overlay {
    background-color: rgba(255, 255, 255, 0.334); 
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; 
  }
  .custom-page{
    min-height: 100vh;
  }

  @media screen {
    #printSection {
        display: none;
    }
  }
  
  .th-td-fix1{
    position: sticky !important;
    top:0;
    left: 0;
    z-index: 5;
}
.th-td-fix2{
    position: sticky !important;
    top:0;
    left: 50px;
    z-index: 5;
}
.td-fix1{
    position: sticky !important;
    z-index: 3;
    left: 0;
    background-color: white;
}
.td-fix2{
    position: sticky !important;
    z-index: 3;
    left: 50px;
    background-color: white;
}

.hidden{
    width: 0;
    height: 0;
    border: none;
}
.overflow-size{
    overflow-x: auto;
    overflow-y: auto;
}
.head-sticky{
    position: sticky;
    top:0 !important;
    z-index: 99;
} 
.container-fluid{
    padding-left: 33px;
}
.w30{
    width: 30px;
}
.w50{
    width: 50px;
}
.w90{
    width: 90px;
}
.w100{
    width: 100px;
}
.w120{
    width: 120px;
}
.w125{
    width: 125px;
}
.w140{
    width: 140px;
}
.w150{
    width: 150px;
}
.w158{
    width: 158px;
}
.w200{
    width: 200px;
}
.w350{
    width: 350px;
}
.w400{
    width: 400px;
}
.w20c{
    width: 20%;
}
.w50c{
    width: 50%;
}
.w90c{
    width: 90%;
}
.w100c{
    width: 100%;
}
.height-100{
    height: 100px;
}
.height-400{
    height: 400px;
}
.text-center{
    text-align: center;
}
.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}
.break-word{
    word-break:break-word;
}
.break-all{
    word-break:break-all;
}
.c-red {
    color: red !important;
}
.c-light-blue{
    color: #009FDA;
}
.c-blue{
    color: #00338D;
}
.c-green{
    color: #70a801;
}
.c-grey{
    color: #747678;
}
.margin-5{
    margin: 5px 5px 5px 5px;
}
.margin-top--16{
    margin-top: -16px;
 }
 .margin-top-5{
    margin-top: 5px;
 }
.margin-top-10{
    margin-top: 10px;
 }
 .margin-top-15{
    margin-top: 15px;
 }
 .margin-top-20{
    margin-top: 20px;
 }
 .margin-top-25{
    margin-top: 25px;
 }
 .margin-top-30{
    margin-top: 30px;
 }
 .margin-top-55{
    margin-top: 55px;
 }
 .margin-top-60{
    margin-top: 60px;
 }
 .margin-top-10c{
    margin-top: 10%
 }
 .margin-left--9{
    margin-left: -9;
 }
 .margin-left--20{
    margin-left: -20;
 }
 .margin-left-0{
    margin-left: 0;
 }
 .margin-left-3{
    margin-right: 3px;
 }
 .margin-left-5{
    margin-left: 5px;
 }
 .margin-left-8{
    margin-left: 8px;
 }
 .margin-left-10{
    margin-left: 10px;
 }
 .margin-left-22{
    margin-left: 22px;
 }
 .margin-left-45c{
    margin-left: 45%;
 }
 .margin-right-5{
    margin-right: 5px;
 }
 .margin-right-8{
    margin-right: 8px;
 }
 .margin-right-10{
    margin-right: 10px;
 }
 .margin-bottom-5{
    margin-bottom: 5px;
 }
 .margin-bottom-10{
    margin-bottom: 10px;
 }
 .margin-bottom-25{
    margin-bottom: 25px;
 }
 .margin-bottom-45{
    margin-bottom: 45px;
 }
.display-none{
    display: none;
}
.display-flex{
    display: flex;
}
.display-inline{
    display: inline;
}
.display-block{
    display: block;
}
.font-size-15{
    font-size: 15px;
}

.font-size-30{
    font-size: 30px;
}
.bg-footer{
    background-image: url('../public/images/footer_master.png');
}
.layout-card {
    width: 100% ;
    height: 50px;
    margin-bottom: 0;
}
.layout-card-h4{
    color: rgb(18, 25, 38);
    margin: 12px;
}

.space-pad{
    padding: 0 8px 4px;
}

.divider-margin{
    margin: 8px 0
}
.padding-0{
    padding: 0;
}
.padding-0-b5{
    padding: 0 0 5px 0;
}
.padding-top-5{
    padding-top: 5px;
}
.padding-top-6{
    padding-top: 6px;
}
.padding-top-8{
    padding-top: 8px;
}
.padding-top-10{
    padding-top: 10px;
}
.padding-top-15{
    padding-top: 15px;
}
.padding-top-32{
    padding-top: 32px;
}
.padding-top-45{
    padding-top: 45px;
}

.padding-bottom-10{
    padding-bottom: 10px;
}
.padding-bottom-15{
    padding-bottom: 15px;
}
.padding-bottom-30{
    padding-bottom: 30px;
}
.padding-bottom-80{
    padding-bottom: 80px;
}
.margin-auto{
    margin: auto;
}

.float-left{
    float: left;
}
.float-right{
    float: right;
}
.text-confirm{
    width: 93%;
    border-color:#ccc ;
    border-radius: 5px;
}

.sticky-btn{
    cursor: pointer;
    float: right;
    margin-right: 10px;
}

.pointer{
    cursor: pointer;
}
.min-width-130{
    min-width: 130px;
}
.min-width-200{
    min-width: 200px;
}
.vertical-align-top{
    vertical-align: top;
}
.vertical-align-bottom{
    vertical-align: bottom;
}
.icon-copy{
    cursor: pointer;
    font-size: 25px;
    color: #009fda
}
.bg-grey{
    background-color: grey;
}
.bg-light{
    background-color: rgba(0, 0, 0, 0.04);
}
.position-absolute{
    position: absolute;
}
.font-weight-500{
    font-weight: 500;
}
.font-weight-normal{
    font-weight: normal;
}
.font-weight-bold{
    font-weight: bold;
}
.just-space-between{
    justify-content: space-between;
}
.widows-100c{
    widows: 100%;
}
.btn-print{
    color:#009FDA;
    border-color: #009FDA;
    margin-left: 5px;
}
.min-height-100{
    min-height: 100px;
}
.min-height-150{
    min-height: 150px;
}
.min-height-340{
    min-height: 340px;
}
.min-height-442{
    min-height: 442px;
}
.select-carton-location{
    min-width: 250px !important;
    margin-left: 10px;
}
.border-red{
    border:1px solid red;
}

.overflow-x-scroll{
    overflow-x: scroll;
}

.sticky-search{
    background: white;
}

.sticky-bg-white{
    background: white;
}

.sticky-bg-grey{
    background: #F7F8FA;
}

.sticky-space{
    height: 10px;
    margin-left: -10px;
}

.sticky-space2{
    height: 33px;
    margin-left: -10px;
    z-index: 1;
}

.sticky-space2-2{
    height: 34px;
    margin-left: -10px;
    z-index: 1;
}

.sticky-left-0{
  left: 0;
}

.sticky-data-top{
    border: none;
    padding: 15px;
    background: white;
    position: relative;
    z-index: 3;
    background-color: white;
}

.sticky-data-bottom{
    border: none;
    padding: 15px;
    padding-top: 8px;
    background: white;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    /* min-height: 800px; */
}

.sticky-border-top{
    border-top: 1px solid #ddd;
}

.sticky-border-left{
    border-left: 1px solid #ddd;
}

.sticky-border-right{
    border-right: 1px solid #ddd;
}

.sticky-border-bottom{
    border-bottom: 1px solid #ddd;
}
.carton-search-top{
    border: 1px solid #ddd;
    height: 48px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    cursor: pointer;
    background-color: white;
    padding: 10px;
    background-color: white;
    z-index: 9;
}
.carton-search-top-img{
    font-size: 13px;
    vertical-align: middle;
}
.carton-search-top span{
    margin-left: 10px;
    vertical-align: middle;
}

.carton-search-bottom{
    padding: 15px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    position: relative;
    z-index: 9 !important;
    background-color: white;
}

.height-50{
    height: 50px;
}
.height-60{
    height: 60px;
}
.height-100{
    height: 100px;
}
.height-380{
    height: 380px;
}

.overflow-y-hidden{
    overflow-y: hidden;
}
.manual-img{
    width: 50px;
    margin-top:7px;
}
.text-left-20{
    padding-left: 20px !important;
}
.logo-ptt{
    cursor: pointer;
}
.font09em{
    font-size: 0.9em
}
.font08em{
    font-size: 0.8em
}
.font075em{
    font-size: 0.75em
}
.font07em{
    font-size: 0.7em
}
.no-data{
    display: inline;
    color: #9f9b9b;
    font-size: 13px;
    font-weight: bold;
}
.card-layout{
    width: 100%;
    height: 50;
    margin-bottom: 0;
}
.mb0-layout{
    color: rgb(18, 25, 38);
    margin: 12 ;
}
.space-padding{
    padding: 0 8px 4px;
}
.divider-padding{
    margin: 8px 0;
}
.apexcharts-xaxis text, .apexcharts-yaxis text,.apexcharts-title-text{
    fill: #444 !important;
}
.apexcharts-legend-text{
    fill: #444 !important;
    font-weight: bold !important;
}
.select-term{
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
}