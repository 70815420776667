#page-topbar {
  background-color: #00ADEF !important;
  z-index: 999;
}

.user .user-name {
  color: #fff;
}

.user .user-sub-title {
  color: #fff;
}

:where(.css-dev-only-do-not-override-htwhyh).ant-menu-light .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-htwhyh).ant-menu-light>.ant-menu .ant-menu-item-selected {
  color: #00ADEF;
}

.ant-menu-item-selected span {
  color: #00ADEF;
}